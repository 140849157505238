"use client";
import Lottie from "lottie-react";
import loading from "../../public/assets/animations/cartLoading.json";

type Props = {
  className?: string;
};

const Loader: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={`
      h-full
      w-full
      flex 
      justify-center 
      items-center
      z-50
      absolute
      ${className}
    `}
    >
      <div className="shadow-cart bg-white rounded-xl p-2">
        <Lottie animationData={loading} loop={true} className="w-24 h-24 " />
      </div>
    </div>
  );
};

export default Loader;
